import { v4 as uuid } from 'uuid';
import dchAPI from '@/services/dchAPI';
import { demoAssets } from '@/services/demo.js';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const state = {
  newlyCreatedApiKey: null,
  outcomes: [],
};

const getters = {
  allOutcomes: (state) => {
    return state.outcomes
      .filter((outcome) => outcome.deleted != true)
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  oneOutcome: (state) => (id) => {
    return state.outcomes.find((outcome) => outcome.id == id);
  },
  outcomesBySource: (state) => (source) => {
    return state.outcomes.filter(
      (outcome) => outcome.sources.includes(source) && outcome.deleted != true
    );
  },
  outcomesByAsset: (state) => (asset) => {
    return state.outcomes.filter(
      (outcome) => outcome.assets.includes(asset) && outcome.deleted != true
    );
  },
};

const actions = {
  async fetchOutcomesData({ commit }) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get('outcomes')
        .then((response) => {
          const responseData = response.data.data.map((r) => transformOutcomesResponseData(r));
          commit('setOutcomesData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchOutcomesBySource({ commit }, source) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get(`outcomes?sources=${source}`)
        .then((response) => {
          const responseData = response.data.data.map((r) => transformOutcomesResponseData(r));
          commit('setOutcomesData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchOutcomeData({ commit }, id) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get(`outcomes/${id}`)
        .then((response) => {
          const outcomeData = {
            ...response.data.data,
            id: response.data.data.api_key_id,
            type: 'Ankeri API',
            result_type: 'API',
            active: false,
            clicked: false,
            not_connected: false,
            central: false,
            expired:
              response.data.data.end_time != null &&
              dayjs.utc().diff(response.data.data.end_time) > 0
                ? true
                : false,
            expired_warning_message: 'Outcome has expired',
            api_key: state.newlyCreatedApiKey,
          };
          commit('updateOutcomeData', outcomeData);
          commit('resetNewlyCreatedApiKey');
          resolve(outcomeData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async updateOutcome({ commit }, model) {
    const updatedOutcome = {
      name: model.name,
      description: model.description,
      enabled: model.enabled,
      start_time: model.start_time,
      end_time: model.end_time,
      assets: model.assets.map((a) => a.asset_id),
      tags: model.tags.map((t) => t.handle),
      sources: model.sources.map((source) => source.handle),
      endpoints: model.endpoints.map((endpoint) => endpoint.id || endpoint),
    };
    if (model.renew_api_key == true) {
      updatedOutcome.api_key = uuid();
    }
    if (model.limit_to_period == true) {
      updatedOutcome.start_time = dayjs.utc(model.start_time).format('YYYY-MM-DDTHH:mm:ss+00:00');
      updatedOutcome.end_time = dayjs.utc(model.end_time).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
    return new Promise((resolve, reject) => {
      dchAPI
        .put(`outcomes/${model.id}`, updatedOutcome)
        .then((response) => {
          const data = response.data.data;
          const responseData = {
            ...data,
            id: data.api_key_id,
            type: 'Ankeri API',
            result_type: 'API',
            active: false,
            clicked: false,
            not_connected: false,
            central: false,
            api_key: updatedOutcome.api_key ? data.api_key_id + '.' + updatedOutcome.api_key : null,
          };
          commit('updateOutcome', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteOutcome({ commit }, id) {
    return new Promise((resolve, reject) => {
      dchAPI
        .delete(`outcomes/${id}`)
        .then((response) => {
          commit('deleteOutcome', id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async newOutcome({ commit }, model) {
    const api_key = uuid();
    const newOutcome = {
      name: model.name,
      description: model.description,
      api_key: api_key,
      start_time: model.start_time,
      end_time: model.end_time,
      sources: model.sources.map((source) => source.handle),
      assets: model.assets.map((a) => a.asset_id),
      tags: model.tags.map((t) => t.handle),
      endpoints: model.endpoints.map((endpoint) => endpoint.id || endpoint),
    };
    if (model.limit_to_period == true) {
      newOutcome.start_time = dayjs.utc(model.start_time).format('YYYY-MM-DDTHH:mm:ss+00:00');
      newOutcome.end_time = dayjs.utc(model.end_time).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
    return new Promise((resolve, reject) => {
      dchAPI
        .post('outcomes', newOutcome)
        .then((response) => {
          const data = response.data.data;
          const responseData = {
            ...data,
            id: data.api_key_id,
            type: 'Ankeri API',
            result_type: 'API',
            active: false,
            clicked: false,
            not_connected: false,
            central: false,
            api_key: data.api_key_id + '.' + api_key,
          };
          commit('newOutcome', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  updateOutcomeData: (state, updatedOutcome) => {
    state.outcomes = state.outcomes.map((outcome) =>
      outcome.id === updatedOutcome.id ? updatedOutcome : outcome
    );
  },
  setOutcomesData: (state, outcomes) => {
    state.outcomes = outcomes;
  },
  updateOutcome: (state, updatedOutcome) => {
    state.outcomes = state.outcomes.map((outcome) =>
      outcome.id === updatedOutcome.id ? updatedOutcome : outcome
    );

    // Storing the API key until user is redirected to the updated outcome
    state.newlyCreatedApiKey = updatedOutcome.api_key;
  },
  deleteOutcome: (state, id) => {
    state.outcomes = state.outcomes.filter((outcome) => outcome.id != id);
  },
  newOutcome: (state, newOutcome) => {
    state.outcomes = [...state.outcomes, newOutcome];

    // Storing the API key until user is redirected to the new outcome
    state.newlyCreatedApiKey = newOutcome.api_key;
  },
  resetNewlyCreatedApiKey(state) {
    state.newlyCreatedApiKey = null;
  },
};

const transformOutcomesResponseData = (data) => {
  return {
    ...data,
    id: data.api_key_id,
    type: 'Ankeri API',
    result_type: 'API',
    active: false,
    clicked: false,
    not_connected: false,
    central: false,
    expired: data.end_time != null && dayjs.utc().diff(data.end_time) > 0 ? true : false,
    expired_warning_message: 'Outcome has expired',
    hidden: false,
    show_only_connected_to: false,
    assets: process.env.VUE_APP_IS_DEMO
      ? data.assets.filter((asset_id) => demoAssets.map((a) => a.imo).includes(asset_id))
      : data.assets,
  };
};

export default {
  state,
  getters,
  actions,
  mutations,
};
