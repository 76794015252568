<template>
  <v-app-bar app color="surface">
    <v-container class="py-0 fill-height">
      <!-- Ankeri logo -->
      <v-btn color="transparent" router :to="'/'">
        <img
          :src="getImg('./logo_ankeri.svg')"
          alt="Ankeri Data Connections Hub"
          class="ankeri-logo"
        />
      </v-btn>
      <!-- Header navigation buttons -->
      <v-btn v-for="link in links" :key="link.path" variant="text" router :to="link.path">
        <!-- Error badge if fetching a source failed -->
        <v-badge
          v-if="link.label === 'Sources' && showErrorBadge"
          :value="true"
          color="transparent"
          right
          offset-y="-6"
          offset-x="-6"
          icon="mdi-alert-circle"
        >
          <!-- v-btn text -->
          {{ link.label }}
        </v-badge>

        <!-- v-btn text -->
        <template v-else>
          {{ link.label }}
        </template>
      </v-btn>

      <v-spacer></v-spacer>

      <v-icon size="large" color="grey" icon="mdi-account-circle" class="mr-1" />
      <v-list-item-title class="mx-2"
        >{{ email }}
        <v-list-item-subtitle class="mx-2"
          ><v-chip variant="outlined" size="x-small">{{
            userCompany
          }}</v-chip></v-list-item-subtitle
        >
      </v-list-item-title>

      <v-menu
        :model-value="menu"
        :close-on-content-click="false"
        location="bottom right"
        width="270"
      >
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon v-bind="props"></v-app-bar-nav-icon>
        </template>

        <v-card>
          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="toggleTheme">
              <template #prepend>
                <v-avatar :color="theme.global.current.value.dark ? 'white' : 'black'">
                  <v-icon
                    :icon="
                      theme.global.current.value.dark ? 'mdi-weather-sunny' : 'mdi-weather-night'
                    "
                  />
                </v-avatar>
              </template>
              <v-list-item-title>
                {{ theme.global.current.value.dark ? 'Brighten' : 'Darken' }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item router :to="{ name: 'api' }" class="my-2">
              <template #prepend>
                <v-avatar color="primary">
                  <v-icon icon="mdi-text-box" />
                </v-avatar>
              </template>
              <v-list-item-title>View API docs</v-list-item-title>
            </v-list-item>
            <v-list-item router :to="{ name: 'config' }" class="my-2">
              <template #prepend>
                <v-avatar color="grey">
                  <v-icon icon="mdi-cog-outline" />
                </v-avatar>
              </template>
              <v-list-item-title>Config</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item router :to="{ name: 'logout' }" class="my-2">
              <template #prepend>
                <v-avatar color="secondary">
                  <v-icon icon="mdi-logout-variant" />
                </v-avatar>
              </template>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import toasters from '@/mixins/toasters.js';
import images from '@/mixins/images';
import { useTheme } from 'vuetify';
import localStorageService from '@/services/localStorageService';
import configService from '@/services/configService';
import { mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  mixins: [images, toasters],
  setup() {
    const theme = useTheme();

    return {
      theme,
      toggleTheme: () => {
        theme.global.name.value = theme.global.current.value.dark
          ? 'customLightTheme'
          : 'customDarkTheme';
        localStorageService.setIsDarkMode(theme.global.current.value.dark);
      },
    };
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      darkMode: false,
      drawer: null,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' },
      ],
      dropDownLinks: [{ path: '', label: 'Logout' }],
      links: [
        { path: '/', label: 'Connections' },
        { path: '/sources', label: 'Sources' },
        { path: '/assets', label: 'Assets' },
        { path: '/outcomes', label: 'Outcomes' },
        { path: '/data', label: 'Data' },
        { path: '/alerts', label: 'Alerts' },
      ],
    };
  },
  computed: {
    ...mapGetters(['getUserEmail', 'allSources']),
    email() {
      return this.getUserEmail;
    },
    showErrorBadge() {
      // Check if fetch of any of the sources failed.
      return this.$store.getters.allSources
        .filter((source) => source.enabled)
        .some((source) => source.last_fetch_successful === false);
    },
    showAlerts() {
      return process.env.NODE_ENV != 'production';
    },
    userCompany() {
      if (process.env.VUE_APP_IS_DEMO) {
        return 'DEMO MODE';
      }
      let company_id = localStorageService.getDchCompanyId();
      if (company_id) {
        let companies = configService.companies();
        return companies.find((c) => c.company_id == company_id)?.name ?? company_id;
      }
      return 'N/A';
    },
  },
  mounted() {
    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');
    // check for active theme
    let htmlElement = document.documentElement;
    let theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      htmlElement.setAttribute('theme', 'dark');
      this.darkMode = true;
    } else {
      htmlElement.setAttribute('theme', 'light');
      this.darkMode = false;
    }
  },
  watch: {
    darkMode: function () {
      // add/remove class to/from html tag
      let htmlElement = document.documentElement;
      if (this.darkMode) {
        localStorage.setItem('theme', 'dark');
        htmlElement.setAttribute('theme', 'dark');
      } else {
        localStorage.setItem('theme', 'light');
        htmlElement.setAttribute('theme', 'light');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  margin-left: 10px;
  margin-right: 10px;
}
.img {
  width: 26px;
}
.ankeri-logo {
  width: 100px;
}
.v-container {
  max-width: 1344px !important;
}
</style>
