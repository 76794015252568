import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const state = {
  initialAuthChecked: null,
  initialDataLoaded: false,
  routeIsLoading: false,
  dataIsConnected: false,
};

const getters = {
  initialAuthChecked: (state) => state.initialAuthChecked,
  initialDataLoaded: (state) => state.initialDataLoaded,
  routeIsLoading: (state) => state.routeIsLoading,
  dataIsConnected: (state) => state.dataIsConnected,
};

const mutations = {
  setInitialAuthChecked: (state, initialAuthChecked) => {
    state.initialAuthChecked = initialAuthChecked;
  },
  setInitialDataLoaded: (state, initialDataLoaded) => {
    state.initialDataLoaded = initialDataLoaded;
  },
  setRouteIsLoading(state, routeIsLoading) {
    state.routeIsLoading = routeIsLoading;
  },
  setDataIsConnected(state, dataIsConnected) {
    state.dataIsConnected = dataIsConnected;
  },
};

const connectData = (rootState, commit) => {
  // we expect all data to be loaded at this point
  let availableTagIds = rootState.tags.tags.map((t) => t.id);

  // connections for sources
  rootState.sources.sources.forEach((s) => {
    // collect tags not in the official tags list
    s.unknown_tags = s.tags.filter((t) => !availableTagIds.includes(t));
    // collect tags in the official tags list
    s.tags = s.tags.filter((t) => availableTagIds.includes(t));
    s.assets = rootState.assets.assets
      .filter((a) => a.sources.some((source) => source.handle === s.handle))
      .map((a) => a.identifier);
    s.outcomes = rootState.outcomes.outcomes
      .filter((o) => o.sources.includes(s.handle))
      .map((o) => o.id);
  });

  // connections for tags
  rootState.tags.tags.forEach((t) => {
    // using id since we might have demo sources only with the id and not the handle
    t.sources = rootState.sources.sources.filter((s) => s.tags.includes(t.id)).map((s) => s.id);
    t.assets = rootState.sources.sources
      .filter((s) => t.sources.includes(s.handle))
      .reduce((assets, source) => {
        const sourceAssets = rootState.assets.assets
          .filter((asset) => source.assets.includes(asset.identifier))
          .map((asset) => asset.identifier);
        return assets.concat(sourceAssets);
      }, [])
      .reduce((uniqueAssets, assetId) => {
        if (!uniqueAssets.some((a) => a === assetId)) {
          uniqueAssets.push(assetId);
        }
        return uniqueAssets;
      }, []);
    t.outcomes = rootState.outcomes.outcomes
      .filter((o) => o.tags.includes(t.handle))
      .map((o) => o.id);
  });

  // connections for outcomes
  rootState.outcomes.outcomes.forEach((o) => {
    o.assets =
      o.assets.length > 0
        ? rootState.assets.assets
            .filter((a) => o.assets.includes(a.identifier))
            .map((a) => a.identifier)
        : rootState.assets.assets
            .filter((a) => a.sources.some((s) => o.sources.includes(s.handle)))
            .map((a) => a.identifier);
    o.tags =
      o.tags.length > 0
        ? rootState.tags.tags.filter((t) => o.tags.includes(t.handle)).map((t) => t.handle)
        : o.sources.length > 0
        ? rootState.sources.sources
            .filter((s) => o.sources.includes(s.handle))
            .map((s) => s.tags)
            .reduce((uniqueTags, tag) => {
              if (!uniqueTags.some((t) => t === tag)) {
                uniqueTags.push(tag);
              }
              return uniqueTags;
            }, [])
        : availableTagIds;
  });

  // connections for assets
  rootState.assets.assets.forEach((a) => {
    //a.sources = state.sources.filter((s) => s.assets.includes(a.identifier)).map((s) => s.handle);
    a.tags = rootState.sources.sources
      .filter((s) => s.assets.includes(a.identifier))
      .reduce((tags, source) => {
        return tags.concat(source.tags);
      }, [])
      .reduce((uniqueTags, tag) => {
        if (!uniqueTags.some((t) => t === tag)) {
          uniqueTags.push(tag);
        }
        return uniqueTags;
      }, []);
    a.outcomes = rootState.outcomes.outcomes
      .filter((o) => o.assets.includes(a.identifier))
      .map((o) => o.id);
  });
  commit('setDataIsConnected', true);
};

const actions = {
  fetchInitialData({ commit, dispatch }) {
    return Promise.all([
      dispatch('fetchConfig'),
      dispatch('fetchSourcesData'),
      dispatch('fetchOutcomesData'),
      dispatch('fetchTagsData'),
      dispatch('fetchAssetsData'),
    ])
      .then(() => {
        commit('setInitialDataLoaded', true);
      })
      .then(() => {
        dispatch('fetchLastSourceDataFetches');
      })
      .then(() => {
        dispatch('processData');
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  checkAuth({ commit, dispatch, getters }) {
    if (getters.isLoggedIn) {
      commit('setInitialAuthChecked', dayjs.utc().format());
      return dispatch('fetchInitialData');
    }
    return Promise.resolve();
  },
  startRouteLoading({ commit }) {
    commit('setRouteIsLoading', true);
  },
  stopRouteLoading({ commit }) {
    commit('setRouteIsLoading', false);
  },
  processData({ rootState, commit }) {
    connectData(rootState, commit);
  },
  fetchLastSourceDataFetches({ dispatch, getters }) {
    Promise.all([
      getters.enabledSources.map((source) => {
        return dispatch('fetchDataFetchesBySource', source.handle);
      }),
    ]);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
